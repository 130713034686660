
import { defineComponent } from 'vue'
import StackedBarChart1 from '@/midone/components/stacked-bar-chart-1/Main.vue'
import SimpleLineChart from '@/midone/components/simple-line-chart/Main.vue'
import SimpleLineChart1 from '@/midone/components/simple-line-chart-1/Main.vue'
import SimpleLineChart2 from '@/midone/components/simple-line-chart-2/Main.vue'

export default defineComponent({
  components: {
    StackedBarChart1,
    SimpleLineChart,
    SimpleLineChart1,
    SimpleLineChart2
  }
})
